// FailurePage.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./UserProfileModal.css";

const FailurePage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <div className="payment-container">
      <h1>Payment Failed ❌</h1>
      <p>Something went wrong with your payment. Please try again later.</p>
      <button className="payment-button" onClick={handleGoBack}>
        Go Back to Home
      </button>
    </div>
  );
};

export default FailurePage;
