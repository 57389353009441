// import React from 'react'

// const SuccessUrl = () => {
//   return (
//     <div>SuccessUrl</div>
//   )
// }

// export default SuccessUrl



// SuccessPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./UserProfileModal.css";

const SuccessUrl = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <div className="payment-container">
      <h1>Payment Successful 🎉</h1>
      <p>
        Your payment was processed successfully. Thank you for your purchase!
      </p>
      <button className="payment-button" onClick={handleGoBack}>
        Go Back to Home
      </button>
    </div>
  );
};

export default SuccessUrl;
